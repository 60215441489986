
import { gallery } from '@/queries/mediaArchive.gql'
import { formatGalleryData } from '~/utils'
import FileManagerImage from '~/plugins/filemanager/FileManagerImage.vue'
import ththLandingData from '~/assets/content/thth/landing.js'

export default {
  name: 'ThthLanding',
  components: {
    FileManagerImage,
  },
  data() {
    return {
      galleryData: [],
      pageData: {},
      slideOptions: [
        {
          navButtons: false,
          slidesToShow: 1,
          autoplay: true,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                navButtons: true,
              },
            },
          ],
        },
        {
          navButtons: false,
          dots: false,
          slidesToShow: 2,
          infinite: false,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                navButtons: true,
              },
            },
          ],
        },
      ],
    }
  },
  async fetch() {
    try {
      const {
        data: { galleryItems },
      } = await this.$gqlQuery({
        query: gallery,
        fetchPolicy: 'no-cache',
        variables: {
          languageCode: this.$i18n.locale,
          domainId: this.$config.domainId,
          slug: '/media-archive',
        },
      })
      const galleryData = JSON?.parse(galleryItems)?.sections || []
      this.galleryData = formatGalleryData(galleryData)
    } catch (ex) {
      this.$sentry.captureException(ex)
    }
  },
  computed: {
    pageContent() {
      return ththLandingData(this.$i18n, this.pageData)
    },
    heroContent() {
      return this.pageContent.hero || false
    },
    internshipContent() {
      return this.pageContent.internship || false
    },
    programmeContent() {
      return this.pageContent.programme || false
    },
    galleryContent() {
      return this.pageContent.gallery || false
    },
    newsContent() {
      return this.pageContent.news || false
    },
    iotInternshipImages() {
      return this.internshipContent.content.iot.images || false
    },
    b2bInternshipImages() {
      return this.internshipContent.content.b2b.images || false
    },
  },
  created() {
    this.pageData = this.$store.state.page.content || {}
  },
}
