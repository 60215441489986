import { render, staticRenderFns } from "./teltonika-high-tech-hill.com-landing.vue?vue&type=template&id=41fe8aeb"
import script from "./teltonika-high-tech-hill.com-landing.vue?vue&type=script&lang=js"
export * from "./teltonika-high-tech-hill.com-landing.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@3.2.5_vue-temp_n4gdrmfirpmgaryqotnkfbokze/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeroFifteen: require('/src/components/hero/HeroFifteen.vue').default,DsBaseHeading: require('/src/components/ds/base/Heading.vue').default})
