export default function (i18n, pageData = {}) {
  // https://test-crm.teltonika.lt/admin/pages/27/edit
  return {
    hero: {
      title: i18n.t('hh_lander_title'),
      content: i18n.t('hh_lander_text'),
      contactUs: i18n.t('contact_us_btn'),
      image: pageData?.bgImage,
    },
    internship: {
      main: {
        title: i18n.t('hh_lander_join_title'),
      },
      content: {
        iot: {
          title: i18n.t('hh_lander_join_iot_title'),
          content: i18n.t('hh_lander_join_iot_text'),
          btnText: i18n.t('scholarship_how_cta'),
          images: {
            logo: pageData?.files['file.thth.landing.iot_academy_logo'],
            main: pageData?.files['file.thth.landing.iot_academy'],
          },
        },
        b2b: {
          title: i18n.t('hh_lander_join_b2b_title'),
          content: i18n.t('hh_lander_join_b2b_text'),
          btnText: i18n.t('scholarship_how_cta'),
          images: {
            logo: pageData?.files['file.thth.landing.b2b_academy_logo'],
            main: pageData?.files['file.thth.landing.b2b_academy'],
          },
        },
      },
    },
    programme: {
      title: i18n.t('hh_lander_candidate_title'),
      content: i18n.t('hh_lander_candidate_text'),
      btnText: i18n.t('scholarship_how_cta'),
      image: pageData?.files['file.thth.landing.scholarship_programme'],
    },
    gallery: {
      title: i18n.t('hh_lander_latest_photos_title'),
      linkTitle: i18n.t('hh_lander_latest_photos_linkTitle'),
    },
    news: {
      title: i18n.t('hh_lander_news_title'),
      linkTitle: i18n.t('hh_lander_news_linkTitle'),
    },
  }
}
